import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  order: {
    orderItems: [],
    otherDetails: '',
    showStockNotice: false,
    tmpQuantity: {},
    customQuestions: [],
  },
}

const bakerOrderCreationSlice = createSlice({
  name: 'bakerOrderCreation',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    resetOrderItems: (state) => {
      state.order.orderItems = []
    },
    updateBakerOrderDetails: (state, action) => {
      state.order.bakerOrderDetails = action.payload
    },
    updateCompletionDate: (state, action) => {
      state.order.completionDate = action.payload
    },
    updateDepositDate: (state, action) => {
      state.order.depositDue = action.payload
    },
    updateBalanceDate: (state, action) => {
      state.order.balanceDue = action.payload
    },
    selectCustomer: (state, action) => {
      const customer = action.payload

      state.order.customerName = customer.name
      if (customer.contactOnly) {
        state.order.customerId = null
        state.order.customerContactId = customer.id
      } else {
        state.order.customerId = customer.id
        state.order.customerContactId = null
      }
    },
    selectCustomerContact: (state, action) => {
      const customer = action.payload

      state.order.customerName = customer.name
      state.order.customerId = null
      state.order.customerContactId = customer.id
    },
    toggleOrderItem: (state, action) => {
      const offering = action.payload
      const offeringId = offering.offeringId || offering.id

      if (state.order.orderItems.findIndex((oi) => oi.offeringId === offeringId) > -1) {
        state.order.orderItems = state.order.orderItems.filter((oi) => oi.offeringId !== offeringId)
      } else {
        state.order.orderItems.push({
          name: offering.name,
          offeringId,
          categoryId: offering.categoryId,
          quantity: +offering.minQuantity || 0,
          unit: offering.dozenOnly ? 'dozen' : 'individual',
          spec: '',
          stock: offering.stock,
          image: offering.image,
          id: offering.id,
          offeringType: offering.offeringType,
          dozenOnly: offering.dozenOnly,
          hidden: offering.hidden,
          priceCents: offering.priceCents,
          priceType: offering.priceType,
        })
      }
    },
    setOrderItems: (state, action) => {
      const orderItems = action.payload

      orderItems.forEach((orderItem) => {
        state.order.orderItems.push({
          name: orderItem?.offering?.name,
          offeringId: orderItem?.offering?.id ? orderItem.offering.id : orderItem.offeringId,
          categoryId: orderItem.categoryId,
          quantity: orderItem.quantity,
          unit: orderItem.unit,
          spec: orderItem.spec,
          stock: orderItem.offering?.stock,
          priceCents: orderItem.offering.priceCents,
          priceType: orderItem.offering.priceType,
        })
      })
    },
    updateQuantity: (state, { payload: { offeringId, quantity } }) => {
      state.order.orderItems.forEach((oi) => {
        if (oi.offeringId !== offeringId) return
        if (!state.order.tmpQuantity[offeringId]) state.order.tmpQuantity[offeringId] = oi.quantity
        oi.quantity = quantity

        if (
          oi.stock !== null &&
          oi.stock !== undefined &&
          quantity > oi.stock &&
          quantity > state.order.tmpQuantity[offeringId]
        ) {
          state.order.showStockNotice = { name: oi.name, stock: oi.stock }
        }
      })
    },
    updateItemSpec: (state, action) => {
      state.order.orderItems.forEach((oi) => {
        if (oi.offeringId !== action.payload.offeringId) return
        oi.spec = action.payload.text
      })
    },
    disableShowStockNotice: (state) => {
      state.order.showStockNotice = false
    },
    updateQuestionnaire: (state, action) => {
      state.order.customQuestions = action.payload
    },
    setDuplicationOrderData: (state, action) => {
      const {
        addressType,
        attachment,
        depositDue,
        balanceDue,
        subtotalAmountCents,
        totalAmountCents,
        depositAmountCents,
        finalPaymentAmountCents,
        customerFacingFee,
        pickupAddress,
        bakerOrderDetails,
        orderAdjustments,
        inspirationPhotos,
      } = action.payload

      state.order = {
        ...state.order,
        addressType,
        attachment,
        depositDue,
        balanceDue,
        subtotalAmountCents,
        totalAmountCents,
        depositAmountCents,
        finalPaymentAmountCents,
        customerFacingFee,
        pickupAddress,
        bakerOrderDetails,
        orderAdjustments,
        inspirationPhotos,
      }
    },
  },
})

export const {
  selectCustomer,
  selectCustomerContact,
  setOrderItems,
  toggleOrderItem,
  updateQuantity,
  updateItemSpec,
  updateBakerOrderDetails,
  updateCompletionDate,
  updateDepositDate,
  updateBalanceDate,
  reset,
  resetOrderItems,
  disableShowStockNotice,
  updateQuestionnaire,
  setDuplicationOrderData,
} = bakerOrderCreationSlice.actions

export const bakerOrderCreationReducer = bakerOrderCreationSlice.reducer

export const selectCreatingOrder = (state) => state.bakerOrderCreation.order
