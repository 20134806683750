import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  categories: [],
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.categories.push(action.payload)
    },
    removeCategory: (state, action) => {
      const defaultIndex = state.categories.findIndex((category) => category.default)
      const index = state.categories.findIndex((category) => category.id === action.payload)

      const defaultCategory = state.categories[defaultIndex]
      const category = state.categories[index]

      defaultCategory.offerings = [...category.offerings, ...defaultCategory.offerings]
      defaultCategory.offeringsCount = defaultCategory.offerings.length
      if (index > -1) state.categories.splice(index, 1)
    },
    replaceCategory: (state, action) => {
      const index = state.categories.findIndex((category) => category.id === action.payload.id)
      if (index > -1) state.categories[index] = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    updateCategoryOffering: (state, action) => {
      const { offering, updatedOffering } = action.payload
      const categories = state.categories

      let previousCategoryIdx
      let previousOfferingIdx
      let updatedCategoryIdx

      categories.some((category, categoryIdx) => {
        const offeringIdx = category.offerings.findIndex(
          ({ id }) => id === offering.id || id === updatedOffering.id,
        )

        if (updatedOffering.categoryId === category.id) {
          updatedCategoryIdx = categoryIdx
        }

        if (offeringIdx !== -1) {
          previousCategoryIdx = categoryIdx
          previousOfferingIdx = offeringIdx
        }

        // If we have found all required indices, exit early
        return !!previousCategoryIdx && !!updatedCategoryIdx
      })

      const previousCategoryOfferings = [...categories[previousCategoryIdx].offerings]

      if (previousCategoryIdx === updatedCategoryIdx) {
        previousCategoryOfferings[previousOfferingIdx] = updatedOffering

        state.categories[previousCategoryIdx].offerings = previousCategoryOfferings
      } else {
        const updatedCategoryOfferings = [...categories[updatedCategoryIdx].offerings]

        previousCategoryOfferings.splice(previousOfferingIdx, 1)
        updatedCategoryOfferings.push(updatedOffering)

        state.categories[previousCategoryIdx].offerings = previousCategoryOfferings
        state.categories[previousCategoryIdx].offeringsCount = previousCategoryOfferings.length
        state.categories[updatedCategoryIdx].offerings = updatedCategoryOfferings
        state.categories[updatedCategoryIdx].offeringsCount = updatedCategoryOfferings.length
      }
    },
  },
})

export const {
  addCategory,
  removeCategory,
  replaceCategory,
  setCategories,
  updateCategoryOffering,
} = categoriesSlice.actions

export const categoriesReducer = categoriesSlice.reducer
